export default function LoadingSpinner({ className }) {
  return (
    // <div class={`inline-flex items-center justify-center pr-3 ${className}`}>
    //   <div class="dot-flashing"></div>
    // </div>
    <div class={`inline-flex items-center justify-center h-10 pr-3 pt-6 -mb-4 -mt-6 overflow-visible  ${className}`}>
      <svg width="24" height="24" viewBox="0 0 24 24" className="h-full" xmlns="http://www.w3.org/2000/svg">
      <style>
        {`
        .spinner_ajPY {
          transform-origin:center;
          animation:spinner_AtaB .75s infinite linear
        }
        @keyframes spinner_AtaB { 100% { transform: rotate(360deg) }}
        `}
      </style>
      <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" />
      <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
      class="spinner_ajPY" />
      </svg>
      {/* <div class="flex h-14 w-14 items-center justify-center rounded-full bg-gradient-to-tr from-slate-100 to-slate-400 animate-spin z-10">
        <div class="h-9 w-9 rounded-full bg-transparent z-20"></div>
      </div> */}
    </div>
  )
}
