import Fuse from "fuse.js"

import { useGetPlayers } from "@hooks/players/useGetPlayers"
import { Menu, Transition } from '@headlessui/react'
import { UserCircleIcon, UserIcon } from "@heroicons/react/24/outline"
import { PLATFORM_INFO_LIST } from "@constants/games/platforms"


const FUSE_OPTIONS = {
  includeScore: true,
  keys: [
    ...PLATFORM_INFO_LIST.map(({platformId}) => `platforms.${platformId}`),
    'address'
  ]
}




export function PlayerAddressDropdownMenu({ onChange, value }) {
  const data = useGetPlayers()

  const fuse = new Fuse(data ?? [], FUSE_OPTIONS)
  const results = fuse.search(value ?? "")
  console.log({results, data, FUSE_OPTIONS})

  const resultList = results.map(({ item }) => {
    return {
      ...item,
      username: _.find(_.entries(item.platforms), ([platformId, platformUsername]) => platformUsername != '')?.[1] ?? item.address,
    }
  })

  const resultsExist = resultList.length > 0

  return (
    resultsExist &&
      <Menu as="div" className="relative inline-block text-left">

        <Transition
          show={true}
          className="hidden group-focus-within/betinput:block "
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 -ml-6 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-slate-800 shadow-lg ring-opacity-5 focus:outline-none z-20">
            <div className="px-1 py-1 space-y-1 ">
              {
                resultList.map((item) => {
                  return (
                    <Menu.Item
                      className="group flex w-full items-center rounded-md px-2 py-2 text-sm"
                      onClick={() => onChange({ target: { value: item.address } })}
                    >
                      <>
                        <PlayerAddressDropdownItem {...item} onChange={onChange} currentValue={value} />

                      </>

                    </Menu.Item>
                  )
                })
              }
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

  )
}


function PlayerAddressDropdownItem({ address, username, icon, onChange, currentValue }) {
  function handleOnClick() {
    onChange({ target: { value: address } })
  }

  return (
    <div
      onClick={handleOnClick}
      className={`
        group px-2 py-1 text-slate-400 text-base rounded
        cursor-pointer
        bg-opacity-70
        hover:bg-opacity-100
        hover:bg-slate-900
        ${(address == currentValue) && "bg-slate-900 !text-slate-200"}
      `}
    >
      {
        icon
          ?
            <img
              src={icon}
              className={`
                w-5 h-5 inline -mt-1
                text-slate-300 mx-auto rounded-full
              `}
            />
          :
            <UserIcon
              className={`
                w-5 h-5 inline -mt-1
                text-slate-300 mx-auto rounded-full
              `}
            />

      }

      <div className="inline px-2 py-2 font-medium">
        {username}
      </div>
    </div>
  )
}

