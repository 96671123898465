import binanceLogo from '@assets/icons/binance.svg'
import ethLogo from '@assets/icons/eth.svg'
import polygonLogo from '@assets/icons/polygon.svg'
import fantomLogo from '@assets/icons/fantom.svg'
import arbitrumLogo from '@assets/icons/arbitrum.svg'
import avalancheLogo from '@assets/icons/avalanche.svg'
import auroraLogo from '@assets/icons/aurora.svg'
import harmonyLogo from '@assets/icons/harmonyone.svg'
import optimismLogo from '@assets/icons/optimism.svg'
import baseLogo from '@assets/icons/base.svg'
import cronosLogo from '@assets/icons/cronos.svg'
import metisLogo from '@assets/icons/metis.svg'

import ethImg from '@assets/networks/eth.jpg'
import bscImg from '@assets/networks/bsc.jpg'
import polygonImg from '@assets/networks/polygon.jpg'
import fantomImg from '@assets/networks/fantom.jpg'
import arbitrumImg from '@assets/networks/arbitrum.jpg'
import avalancheImg from '@assets/networks/avalanche.jpg'
import dfkImg from '@assets/networks/dfk.png'
import auroraImg from '@assets/networks/aurora.png'
import harmonyImg from '@assets/networks/harmonyone.jpg'
import optimismImg from '@assets/networks/optimism.png'
import bobaImg from '@assets/networks/boba.png'
import moonbeamImg from '@assets/networks/moonbeam.jpg'
import moonriverImg from '@assets/networks/moonriver.jpeg'
import cronosImg from '@assets/networks/cronos.png'




import { toHexStr } from '@utils/toHexStr'


export const NetworkContextName = 'DEFAULT_NETWORK'




export const ChainId = {
  OPTIMISM_GOERLI:  420,

  ETH:          1,
  GÖRLI:        5,
  OPTIMISM:     10,
  CRONOS:       25,
  KOVAN:        42,
  BSC:          56,
  POLYGON:      137,

  BASE:         8453,
  BASE_SEPOLIA: 84532,

  ARBITRUM:     42161,
  AVALANCHE:    43114,
  DFK:          53935,
  AURORA:       1313161554,
  HARMONY:      1666600000,

  TERRA: 121014925, //"columbus-5", the day columbus reportedly landed in america followed by 5
}

export const DEFAULT_CHAIN_ID = ChainId.BASE



export const INVERTED_CHAIN_ID_MAP = Object.fromEntries(
  Object.entries(ChainId).map(([k, v]) => [v, k])
)

export const CHAIN_INFO_MAP = {
  [ChainId.BASE]: {
    chainId: ChainId.BASE,
    chainSymbol: 'BASE',
    chainName: 'Base',
    chainLogo: baseLogo,
    chainImg: baseLogo,
    layer: 2,
  },
  [ChainId.BASE_SEPOLIA]: {
    chainId: ChainId.BASE_SEPOLIA,
    chainSymbol: 'BASE SEPOLIA',
    chainName: 'Base Sepolia',
    chainLogo: baseLogo,
    chainImg: baseLogo,
    layer: 2,
  },
  [ChainId.OPTIMISM_GOERLI]: {
    chainId: ChainId.OPTIMISM,
    chainSymbol: 'GOERLI OPTIMISM',
    chainName: 'Optimism Goerli',
    chainLogo: optimismLogo,
    chainImg: optimismImg,
    layer: 2,
  },
  [ChainId.ETH]: {
    chainId:     ChainId.ETH,
    chainSymbol: 'ETH',
    chainName:   'Ethereum',
    chainLogo:   ethLogo,
    chainImg:    ethImg,
    layer:       1,
  },
  [ChainId.OPTIMISM]: {
    chainId:     ChainId.OPTIMISM,
    chainSymbol: 'OPTIMISM',
    chainName:   'Optimism',
    chainLogo:   optimismLogo,
    chainImg:    optimismImg,
    layer:       2,
  },
  [ChainId.GÖRLI]: {
    chainId: 5,
    chainSymbol: 'GOERLI',
    chainName: 'Görli',
    chainLogo: ethLogo,
    chainImg: ethImg,
    layer: 1,
  },
  [ChainId.CRONOS]: {
    chainId:     ChainId.CRONOS,
    chainSymbol: 'CRONOS',
    chainName:   'Cronos',
    chainLogo:   cronosLogo,
    chainImg:    cronosImg,
    layer:       1,
  },
  [ChainId.BSC]: {
    chainId:        ChainId.BSC,
    chainSymbol:    'BNB',
    chainName:      'BNB Chain',
    chainShortName: 'BNB',
    chainLogo:      binanceLogo,
    chainImg:       bscImg,
    layer:          1,
  },
  [ChainId.POLYGON]: {
    chainId:     ChainId.POLYGON,
    chainSymbol: 'POLYGON',
    chainName:   'Polygon',
    chainLogo:   polygonLogo,
    chainImg:    polygonImg,
    layer:       2,
  },
  [ChainId.ARBITRUM]: {
    chainId:     ChainId.ARBITRUM,
    chainSymbol: 'ARBITRUM',
    chainName:   'Arbitrum',
    chainLogo:   arbitrumLogo,
    chainImg:    arbitrumImg,
    layer:       2,
  },
  [ChainId.AVALANCHE]: {
    chainId:     ChainId.AVALANCHE,
    chainSymbol: 'AVALANCHE',
    chainName:   'Avalanche',
    chainLogo:   avalancheLogo,
    chainImg:    avalancheImg,
    layer:       1,
  },
  [ChainId.DFK]: {
    chainId:     ChainId.DFK,
    chainSymbol: 'DFK',
    chainName:   'DFK Chain',
    chainLogo:   dfkImg,
    chainImg:    dfkImg,
    layer:       1,
  },
  [ChainId.AURORA]: {
    chainId:     ChainId.AURORA,
    chainSymbol: 'AURORA',
    chainName:   'Aurora',
    chainLogo:   auroraLogo,
    chainImg:    auroraImg,
    layer:       1,
  },
  [ChainId.HARMONY]: {
    chainId:     ChainId.HARMONY,
    chainSymbol: 'HARMONY',
    chainName:   'Harmony',
    chainLogo:   harmonyLogo,
    chainImg:    harmonyImg,
    layer:       1,
  },
}


// export const SUPPORTED_CHAINS = Object.keys(CHAIN_INFO_MAP).filter(({chainId}) => _.isNumber(chainId))

export const CHAIN_ID_DISPLAY_ORDER = [
  ChainId.ETH,
  ChainId.BASE,
  ChainId.BASE_SEPOLIA,
  ChainId.GÖRLI,
  ChainId.ARBITRUM,
  ChainId.BSC,
  ChainId.OPTIMISM,
  ChainId.POLYGON,
]

// main read rpcs, for write, it may be overridden
export const CHAIN_RPC = {
  [ChainId.BASE]: 'https://base.llamarpc.com',                                               //https://mainnet.base.org
  [ChainId.BASE_SEPOLIA]: 'https://sepolia.base.org',

  [ChainId.OPTIMISM_GOERLI]: 'https://goerli.optimism.io',
  [ChainId.GÖRLI]:           'https://eth-goerli.g.alchemy.com/v2/VOU0hPP00bGYhlVio9UEyftJ5N08nwFO',
  [ChainId.ETH]:             'https://eth-mainnet.g.alchemy.com/v2/HjZaFp4yaG1q3cOhkCXl5l21clCD620r',   //'https://eth-mainnet.alchemyapi.io/v2/2KGc5anzcDyuKCRT3EXPd7QanER_vvJq',
  [ChainId.OPTIMISM]:        'https://1rpc.io/op',
  [ChainId.GÖRLI]:           'https://goerli.infura.io/v3',
  [ChainId.BSC]:             'https://bsc-dataseed1.ninicoin.io/',
  [ChainId.POLYGON]:         'https://poly-rpc.gateway.pokt.network',
  [ChainId.AVALANCHE]:       'https://api.avax.network/ext/bc/C/rpc',
  [ChainId.DFK]:             'https://subnets.avax.network/defi-kingdoms/dfk-chain/rpc',
  [ChainId.ARBITRUM]:        'https://arb1.arbitrum.io/rpc',
  [ChainId.AURORA]:          'https://mainnet.aurora.dev',
  [ChainId.HARMONY]:         'https://harmony-0-rpc.gateway.pokt.network/',
  [ChainId.CRONOS]:          'https://evm-cronos.crypto.org',

  // [ChainId.XDAI]: 'https://rpc.xdaichain.com',
}

export const CHAIN_EXPLORER_URLS = {
  [ChainId.BASE]:         'https://basescan.org/',
  [ChainId.BASE_SEPOLIA]: 'https://sepolia.basescan.org/',
  [ChainId.OPTIMISM]:     'https://goerli-optimistic.etherscan.io',
  [ChainId.BSC]:          'https://bscscan.com',
  [ChainId.ETH]:          'https://etherscan.com',
  [ChainId.GÖRLI]:        'https://goerli.etherscan.io',
  [ChainId.POLYGON]:      'https://polygonscan.com',

  [ChainId.ARBITRUM]:  'https://arbiscan.io',
  [ChainId.OPTIMISM]:  'https://optimistic.etherscan.io',
  [ChainId.AVALANCHE]: 'https://snowtrace.io',
  [ChainId.DFK]:       'https://subnets.avax.network/defi-kingdoms/',
  [ChainId.FANTOM]:    'https://ftmscan.com',
  [ChainId.HARMONY]:   'https://explorer.harmony.one',
  [ChainId.AURORA]:    'https://explorer.mainnet.aurora.dev',
  [ChainId.CRONOS]:    'https://cronoscan.com/',
               //'https://finder.terra.money/mainnet',
}

/** write rpcs */
const WRITE_CHAIN_RPC = {
  ...CHAIN_RPC,
  [ChainId.ETH]:  'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
}



const ETH_NATIVE_CURRENCY = {
  name:     'Ethereum',
  symbol:   'ETH',
  decimals: 18,
}




/**
 * The below need to be MetaMask compatible keys/objects.
 * extra keys can cause MetaMask to cause really unexpected errors
 * - The order is the order displayed in the chain selector, this is stupid but deal with it
 */
export const CHAIN_PARAMS = {
  [ChainId.BASE]: {
    chainId:           toHexStr(ChainId.BASE),
    chainName:         CHAIN_INFO_MAP[ChainId.BASE].chainName,
    nativeCurrency:    ETH_NATIVE_CURRENCY,
    rpcUrls:           [CHAIN_RPC[ChainId.BASE]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.BASE]],
  },
  [ChainId.BASE_SEPOLIA]: {
    chainId:           toHexStr(ChainId.BASE_SEPOLIA),
    chainName:         CHAIN_INFO_MAP[ChainId.BASE_SEPOLIA].chainName,
    nativeCurrency:    ETH_NATIVE_CURRENCY,
    rpcUrls:           [CHAIN_RPC[ChainId.BASE_SEPOLIA]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.BASE_SEPOLIA]],
  },
  [ChainId.OPTIMISM_GOERLI]: {
    chainId:           toHexStr(ChainId.OPTIMISM_GOERLI),
    chainName:         CHAIN_INFO_MAP[ChainId.OPTIMISM_GOERLI].chainName,
    nativeCurrency:    ETH_NATIVE_CURRENCY,
    rpcUrls:           [CHAIN_RPC[ChainId.OPTIMISM_GOERLI]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.OPTIMISM_GOERLI]],
  },
  [ChainId.ETH]: {
    chainId:           toHexStr(ChainId.ETH),
    chainName:         CHAIN_INFO_MAP[ChainId.ETH].chainName,
    nativeCurrency:    ETH_NATIVE_CURRENCY,
    rpcUrls:           [WRITE_CHAIN_RPC[ChainId.ETH]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.ETH]], // might need to add / after explorer url
  },
  [ChainId.GÖRLI]: {
    chainId:           toHexStr(ChainId.GÖRLI),
    chainName:         CHAIN_INFO_MAP[ChainId.GÖRLI].chainName,
    nativeCurrency:    ETH_NATIVE_CURRENCY,
    rpcUrls:           [WRITE_CHAIN_RPC[ChainId.GÖRLI]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.GÖRLI]],      // might need to add / after explorer url
  },
  [ChainId.OPTIMISM]: {
    chainId:           toHexStr(ChainId.OPTIMISM),
    chainName:         CHAIN_INFO_MAP[ChainId.OPTIMISM].chainName,
    nativeCurrency:    ETH_NATIVE_CURRENCY,
    rpcUrls:           [CHAIN_RPC[ChainId.OPTIMISM]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.OPTIMISM]],
  },
  [ChainId.CRONOS]: {
    chainId:           toHexStr(ChainId.CRONOS),
    chainName:         CHAIN_INFO_MAP[ChainId.CRONOS].chainName,
    nativeCurrency: {
      name:     'Cronos',
      symbol:   'CRO',
      decimals: 18,
    },
    rpcUrls:           [CHAIN_RPC[ChainId.CRONOS]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.CRONOS]],
  },
  [ChainId.BSC]: {
    chainId:        toHexStr(ChainId.BSC),
    chainName:      CHAIN_INFO_MAP[ChainId.BSC].chainName,
    nativeCurrency: {
      name:     'Binance Coin',
      symbol:   'BNB',
      decimals: 18,
    },
    rpcUrls:           [CHAIN_RPC[ChainId.BSC]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.BSC]],
  },
  [ChainId.POLYGON]: {
    chainId:        toHexStr(ChainId.POLYGON),
    chainName:      CHAIN_INFO_MAP[ChainId.POLYGON].chainName,
    nativeCurrency: {
      name:     'Matic',
      symbol:   'MATIC',
      decimals: 18,
    },
    rpcUrls:           [CHAIN_RPC[ChainId.POLYGON]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.POLYGON]],
  },
  [ChainId.ARBITRUM]: {
    chainId:           toHexStr(ChainId.ARBITRUM),
    chainName:         CHAIN_INFO_MAP[ChainId.ARBITRUM].chainName,
    nativeCurrency:    ETH_NATIVE_CURRENCY,
    rpcUrls:           [CHAIN_RPC[ChainId.ARBITRUM]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.ARBITRUM]],
  },
  [ChainId.AVALANCHE]: {
    chainId:        toHexStr(ChainId.AVALANCHE),
    chainName:      CHAIN_INFO_MAP[ChainId.AVALANCHE].chainName,
    nativeCurrency: {
      name:     'Avax',
      symbol:   'AVAX',
      decimals: 18,
    },
    rpcUrls:           [CHAIN_RPC[ChainId.AVALANCHE]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.AVALANCHE]],
  },
  [ChainId.DFK]: {
    chainId:        toHexStr(ChainId.DFK),
    chainName:      CHAIN_INFO_MAP[ChainId.DFK].chainName,
    nativeCurrency: {
      name:     'Jewel',
      symbol:   'JEWEL',
      decimals: 18,
    },
    rpcUrls:           [CHAIN_RPC[ChainId.DFK]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.DFK]],
  },
  [ChainId.AURORA]: {
    chainId:           toHexStr(ChainId.AURORA),
    chainName:         CHAIN_INFO_MAP[ChainId.AURORA].chainName,
    nativeCurrency:    ETH_NATIVE_CURRENCY,
    rpcUrls:           [CHAIN_RPC[ChainId.AURORA]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.AURORA]],
  },
  [ChainId.HARMONY]: {
    chainId:        toHexStr(ChainId.HARMONY),
    chainName:      CHAIN_INFO_MAP[ChainId.HARMONY].chainName,
    nativeCurrency: {
      name:     'Harmony One',
      symbol:   'ONE',
      decimals: 18,
    },
    rpcUrls:           [CHAIN_RPC[ChainId.HARMONY]],
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainId.HARMONY]],
  },

}

/**
 * NOTE: this is currently set to a far lower value than what it normally is.  normally 2500
 */
export const BLOCK_TIME = 5000 // 30000   // 5000

