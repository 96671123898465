
import './styles/global.css'

import "./bigIntPatch.js"
// import 'wagmi/window'
const isMetaMask = window.ethereum?.isMetaMask
// import '@bignumber/bootstrap'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'



// import { MEME_ASCII_ART, LOGO_ASCII_ART, THE_PLAN } from '@constants/misc'



import { RainbowProvider } from '@providers/RainbowProvider'

import { ScrollToTop } from '@components/ScrollToTop'


import CustomToaster from '@layouts/CustomToaster'
import App from './App'

// The Plan
// console.log(MEME_ASCII_ART)
// console.log(THE_PLAN)
// console.log(LOGO_ASCII_ART)

console.log("WAGMI")
createRoot(document.getElementById('root')).render(
    // <>
    <RainbowProvider>
      <Router>
        <ScrollToTop />
        <div>
          soon...
        </div>
        {/* <App /> */}
      </Router>
      <CustomToaster />
    </RainbowProvider>
    //  </>
)

console.log(process.env.NODE_ENV)
