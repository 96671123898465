import { ChainId } from "@constants/networks"
import { battleRoyaleConfig } from "@constants/generated"




export const BATTLE_ROYALE_ADDR_ABI = {
  [ChainId.BASE]: {
    ...battleRoyaleConfig,
    address: battleRoyaleConfig.address[ChainId.BASE],
  }
}

// new contract version 0x30d0A829F60acDeD930A171782F7A5c3b5D6372e