import "@rainbow-me/rainbowkit/styles.css";

import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";

import { wagmiConfig } from "@/wagmiConfig";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

// precompute theme
let RAINBOW_KIT_THEME = darkTheme({ overlayBlur: "small" });
RAINBOW_KIT_THEME.colors.modalBackground = "#94a3b825";
RAINBOW_KIT_THEME.colors.modalBorder = "#FFFFFF51";

const queryClient = new QueryClient();

export function RainbowProvider({ children }) {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={RAINBOW_KIT_THEME}>
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
