import toast, { Toaster, ToastBar } from 'react-hot-toast'
import ToastContent from './ToastContent'

export default function CustomToaster() {
  return (
    <Toaster
      position="bottom-right" // top-right
      containerClassName="pt-8 rounded-xl"
      toastOptions={{
        // Define default options
        style: {
          border: "0px",
          background: 'transparent',
          padding: '0px',
        },
        className: `

            rounded-2xl
            shadow-indigo
            text-gray-400
          `,
        duration: 5000,
      }}
    >
      {(toastData) => (
        <ToastBar toast={toastData} style={{}}>
          {(props) => <ToastContent toastData={toastData} {...props} />}
        </ToastBar>
      )}
    </Toaster>
  )
}
