import _ from 'lodash'

import usdcLogo from '@assets/icons/usdc.svg'
import usdtLogo from '@assets/icons/usdt.svg'

import ethLogo from '@assets/icons/eth.svg'
import wethLogo from '@assets/icons/weth.svg'
import daiLogo from '@assets/icons/dai.png'


import { ChainId } from '@constants/networks'

import { Token } from '@utils/classes/Token'




export const USDC = new Token({
  addresses: {
    [ChainId.GÖRLI]: '0x2f3A40A3db8a7e3D09B0adfEfbCe4f6F81927557',
    [ChainId.BSC]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    [ChainId.ETH]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',

    [ChainId.CRONOS]: '0xc21223249ca28397b4b6541dffaecc539bff0c59',
    [ChainId.OPTIMISM]: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    [ChainId.POLYGON]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    [ChainId.AVALANCHE]: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    [ChainId.ARBITRUM]: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    [ChainId.HARMONY]: '0x985458e523db3d53125813ed68c274899e9dfab4',
    [ChainId.AURORA]: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',

    [ChainId.BASE]: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    [ChainId.BASE_SEPOLIA]: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913"
  },
  decimals: {
    [ChainId.BSC]: 18,
    [ChainId.ETH]: 6,
    [ChainId.OPTIMISM]: 6,
    [ChainId.POLYGON]: 6,
    [ChainId.AVALANCHE]: 6,
    [ChainId.ARBITRUM]: 6,
    [ChainId.HARMONY]: 6,
    [ChainId.AURORA]: 6,
    [ChainId.CRONOS]: 6,

    [ChainId.BASE]: 18,
    [ChainId.BASE_SEPOLIA]: 18,

  },
  symbol: 'USDC',
  name: 'USD Circle',
  logo: usdcLogo,
  description: `
    USD Coin (known by its ticker USDC) is a stablecoin that is pegged to the
    U.S. dollar on a 1:1 basis. Every unit of this cryptocurrency in circulation
    is backed up by $1 that is held in reserve
    `,
  swapableType: 'USD',
})


export const USDT = new Token({
  addresses: {
    [ChainId.BSC]: '0x55d398326f99059ff775485246999027b3197955',
    [ChainId.ETH]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    [ChainId.CRONOS]: '0x66e428c3f67a68878562e79a0234c1f83c208770',
    [ChainId.POLYGON]: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    [ChainId.AVALANCHE]: '0xc7198437980c041c805a1edcba50c1ce5db95118',
    [ChainId.ARBITRUM]: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    [ChainId.HARMONY]: '0x3c2b8be99c50593081eaa2a724f0b8285f5aba8f',
    [ChainId.AURORA]: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
  },
  decimals: {
    [ChainId.BSC]: 18,
    [ChainId.ETH]: 6,
    [ChainId.POLYGON]: 6,
    [ChainId.AVALANCHE]: 6,
    [ChainId.ARBITRUM]: 6,
    [ChainId.HARMONY]: 6,
    [ChainId.AURORA]: 6,
    [ChainId.CRONOS]: 6,
  },
  symbol: 'USDT',
  name: 'USD Tether',
  logo: usdtLogo,
  description: `
    USDT mirrors the price of the U.S. dollar, issued by a Hong Kong-based company Tether.
    The token’s peg to the USD is achieved via maintaining a sum of dollars in reserves equal
    to the number of USDT in circulation.
    `,
  swapableType: 'USD',
})

export const DAI = new Token({
  addresses: {
    [ChainId.BSC]: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    [ChainId.ETH]: '0x6b175474e89094c44da98b954eedeac495271d0f',
    [ChainId.CRONOS]: '0xf2001b145b43032aaf5ee2884e456ccd805f677d',
    [ChainId.POLYGON]: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    [ChainId.AVALANCHE]: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    [ChainId.ARBITRUM]: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    [ChainId.HARMONY]: '0xef977d2f931c1978db5f6747666fa1eacb0d0339',
  },
  decimals: 18,
  symbol: 'DAI',
  name: 'Dai',
  logo: daiLogo,
  swapableType: 'USD',
})



export const WETH = new Token({
  addresses: {
    [ChainId.OPTIMISM_GOERLI]: '0x4200000000000000000000000000000000000006',
    [ChainId.GÖRLI]: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
    [ChainId.ETH]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',

    [ChainId.ARBITRUM]: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',

    [ChainId.OPTIMISM]: '0x121ab82b49B2BC4c7901CA46B8277962b4350204',

    [ChainId.BASE]:         '0x4200000000000000000000000000000000000006',
    [ChainId.BASE_SEPOLIA]: '0x4200000000000000000000000000000000000006'
    // [ChainId.AVALANCHE]: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab'
  },
  decimals: 18,
  symbol: 'WETH', // SHOULD BE WETH
  name: 'Wrapped ETH',
  logo: wethLogo,
  description: 'ERC-20 Wrapped form of ETH',
  swapableType: 'ETH',
})



export const ETH = new Token({
  addresses: {
    [ChainId.ETH]:          '',

    [ChainId.BASE]:         '',
    [ChainId.BASE_SEPOLIA]: '',

    [ChainId.ARBITRUM]:     '',
    [ChainId.OPTIMISM]:     '',
  },
  decimals: 18,
  symbol: 'ETH',
  name: 'Ethereum',
  logo: ethLogo,
  description: 'ETH',
  isNative: true,
  swapableType: 'ETH',
})


export const ALLOWED_TOKENS = [WETH, USDC]