import {
  base,
  blast,
} from '@wagmi/core/chains'


import { CHAINS_BY_ID } from '@/constants/chains'
import { BLAST } from '@/constants/chains/master'

export const supportedChains = [
  base,
  // {
  //     ...blast,
  //     iconUrl: BLAST.chainImg.src,
  //     iconBackground: "transparent",
  // }
].map((chain) => {
  return {
    ...chain,
    // configRpc: CHAINS_BY_ID[chain.id]?.rpcUrls.primary,
    // fallbackRpc: CHAINS_BY_ID[chain.id]?.rpcUrls.fallback,
    // iconUrl: CHAINS_BY_ID[chain.id]?.chainImg.src,
  }
})

export const SUPPORTED_CHAIN_IDS = supportedChains.map(chain => chain.id)

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(Number(chainId))
}


export function isSupportedAndTargetChain({ chainId, targetChainId }) {
  return isSupportedChain(chainId) && (targetChainId ? (Number(chainId) === Number(targetChainId)) : true)
}
