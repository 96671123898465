import APEX_SRC from '@assets/games/gameIcons/apex.png'
import COD_SRC from '@assets/games/gameIcons/cod.png'
import CSGO_SRC from '@assets/games/gameIcons/csgo.png'
import DOTA2_SRC from '@assets/games/gameIcons/dota2.jpg'
import FORTNITE_SRC from '@assets/games/gameIcons/fortnite.png'
import LOL_SRC from '@assets/games/gameIcons/lol.jpg'
import VALORANT_SRC from '@assets/games/gameIcons/valorant.png'

import KDR_SRC from "@assets/games/modes/kdr.svg"
import KILLS_SRC from "@assets/games/modes/kills.svg"
import WIN_LOSE_SRC from "@assets/games/modes/winlose.svg"
import GENERIC_SRC from "@assets/games/modes/generic.svg"

import SINGLE_SRC from "@assets/games/matchTypes/single.svg"
import DUO_SRC from "@assets/games/matchTypes/duo.svg"
import TRIO_SRC from "@assets/games/matchTypes/trio.svg"
import QUAD_SRC from "@assets/games/matchTypes/quad.svg"


const GAME_TYPES = {
  VALORANT: {
    label: "Valorant",
    icon: VALORANT_SRC
  },
  // COD:           {
  //   label: "Call of Duty",
  //   icon: COD_SRC
  // },
  // APEX: {
  //   label: "Apex Legends",
  //   icon: APEX_SRC
  // },
  // FORTNITE: {
  //   label: "Fortnite",
  //   icon: FORTNITE_SRC
  // },
  // LOL: {
  //   label: "League of Legends",
  //   icon: LOL_SRC
  // },
  // CSGO: {
  //   label: "CS:GO",
  //   icon: CSGO_SRC
  // },
  // OVERWATCH: {
  //   label: "Overwatch",
  //   icon: undefined
  // },
  // DOTA: {
  //   label: "DOTA 2",
  //   icon: DOTA2_SRC
  // },
  // MINECRAFT: {
  //   label: "Minecraft",
  //   icon: undefined
  // },
  // ROCKET_LEAGUE: {
  //   label: "Rocket League",
  //   icon: undefined
  // },
}

for (const [key, obj] of Object.entries(GAME_TYPES)) {
  GAME_TYPES[key] = {
    ...obj,
    id: key,
  }
}


const GAME_BET_MODES = {
  PLACEMENT: {
    label: "Rank (W/L)",
    icon: WIN_LOSE_SRC
  },
  KILLS:     {
    label: "Kills",
    icon:  KILLS_SRC
  },
  DEATHS:    {
    label: "Deaths",
    icon:  KILLS_SRC
  },
  KDR:       {
    label: "K/D",
    icon:  KDR_SRC
  },
  // GD:        {
  //   label: "Gulag Deaths",
  //   icon:  GENERIC_SRC
  // },
  // HEADSHOTS: {
  //   label: "Headshots",
  //   icon:  GENERIC_SRC
  // },
  // ASSISTS:   {
  //   label: "Assists",
  //   icon:  GENERIC_SRC
  // },
  // WALLBANGS: {
  //   label: "Wallbangs",
  //   icon:  GENERIC_SRC
  // },
  // SCORE:     {
  //   label: "Score",
  //   icon:  WIN_LOSE_SRC
  // },

}



for (const [key, obj] of Object.entries(GAME_BET_MODES)) {
  GAME_BET_MODES[key] = {
    ...obj,
    id: key,
  }
}


const GAME_MODE_SUBTYPES = {
  DEATH_MATCH: {
    label: "Death Match",
    icon: QUAD_SRC,
  },
  COMPETITIVE: {
    label: "Competitive",
    icon: DUO_SRC,
  },
  UNRATED: {
    label: "UNRATED",
    icon: TRIO_SRC,
  },
  // SINGLE: {
  //   label: "Single",
  //   icon: SINGLE_SRC
  // },
  // DUO: {
  //   label: "Duo",
  //   icon: DUO_SRC
  // },
  // TRIO: {
  //   label: "Trio",
  //   icon: TRIO_SRC
  // },
  // QUAD: {
  //   label: "Quad",
  //   icon: QUAD_SRC
  // }
}


for (const [key, obj] of Object.entries(GAME_MODE_SUBTYPES)) {
  GAME_MODE_SUBTYPES[key] = {
    ...obj,
    id: key,
  }
}

export { GAME_TYPES, GAME_BET_MODES, GAME_MODE_SUBTYPES }