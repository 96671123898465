

import { ChainId, CHAIN_EXPLORER_URLS } from '@constants/networks'

// Hardcoding this shit for now until actual plan around routing
console.log(process.env?.NODE_ENV)
let BASE_URL
if (process.env?.NODE_ENV === 'development') {
  BASE_URL = 'http://localhost:3000'
} else {
  BASE_URL = 'https://marker.io'
}


export { BASE_URL }


export const BASE_PATH = '/'

export const HOME_PATH = '/'
export const ADMIN_PATH = '/admin'

export const OUTCOMES_PATH = '/outcomes'
export const OUTCOMES_CREATE_PATH = `${OUTCOMES_PATH}/create`
export const OUTCOMES_MATCH_PATH = `${OUTCOMES_PATH}/match/:id`

export const BATTLE_ROYALE_PATH = '/battleroyale'
export const BATTLE_ROYALE_CREATE_PATH = `${BATTLE_ROYALE_PATH}/create`
export const BATTLE_ROYALE_MATCH_PATH = `${BATTLE_ROYALE_PATH}/match/:id`

export const PVP_PATH = '/pvp'
export const PVP_MATCH_PATH = `${PVP_PATH}/match/:id`
export const PVP_RESOLVE_PATH = `${PVP_PATH}/resolve/:id`
export const PVP_CREATE_PATH = `${PVP_PATH}/create`

export const PLAYERS_PATH = `/players`
export const PLAYER_PATH = `/players/:id`
export const SLOTS_PATH = '/slots'
export const COINFLIP_PATH = '/coinflip'
export const FAIRNESS_PATH = '/fairness'
export const FACTIONS_PATH = '/factions'
export const LEADERBOARD_PATH = '/leaderboard'
export const HISTORY_PATH = '/history'
export const STATISTICS_PATH = '/statistics'


export const ANALYTICS_PATH  = 'https://analytics.marker.com/'


export const LANDING_PATH    = '/landing'



export function getPvpMatchPath({ id }) {
  return (
    `${PVP_PATH}/match/${id}`
  )
}

export function getBattleRoyaleMatchPath({ id }) {
  return (
    `${BATTLE_ROYALE_PATH}/match/${id}`
  )
}

export function getOutcomesMatchPath({ id }) {
  return (
    `${OUTCOMES_PATH}/match/${id}`
  )
}

export function getPlayerPath({ address }) {
  return (
    `${PLAYERS_PATH}/${address}`
  )
}


export function getExplorerUrl({ data, chainId, type }) {
  let baseUrl = CHAIN_EXPLORER_URLS[chainId] ?? CHAIN_EXPLORER_URLS[ChainId.ETH]

  return `${baseUrl}/${type}/${data}`
}


export function getExplorerTxUrl({ hash, data, chainId=5, type="tx" }) {
  let baseUrl = CHAIN_EXPLORER_URLS[chainId] ?? CHAIN_EXPLORER_URLS[ChainId.ETH]

  return `${baseUrl}/${type}/${hash ?? data}`
}

export function getExplorerAddressUrl({ address, chainId=5, type="address" }) {
  let baseUrl = CHAIN_EXPLORER_URLS[chainId] ?? CHAIN_EXPLORER_URLS[ChainId.ETH]

  return `${baseUrl}/${type}/${address}`
}


export function getCompleteUrl(uriPath) {
  return `${BASE_URL}${uriPath}`
}

export const DOCS_URL     = 'https://docs.marker.xyz'
export const DISCORD_URL  = 'https://discord.gg/marker'
export const TELEGRAM_URL = 'https://t.me/marker'
export const FORUM_URL    = 'https://forum.marker.xyz/'
export const TWITTER_URL  = 'https://twitter.com/markerxyz'

export const GITHUB_URL   = 'https://github.com/markrprotocol'
export const MEDIUM_URL   = 'https://markerprotocol.medium.com/'
export const MIRROR_URL   = 'https://marker.mirror.xyz/'






const SUSHISWAP_BASE_URL = "https://app.sushi.com"
// Need to switch this with fei url
function getSushiSwapUrl({fromCoin, toCoin, chainId }) {
  const inputCurrency = fromCoin?.addresses?.[chainId] ?? ""
  const outputCurrency = toCoin?.addresses?.[chainId] ?? ""
  return `${SUSHISWAP_BASE_URL}/swap?inputCurrency=${inputCurrency}&outputCurrency=${outputCurrency}`
}





const UNISWAP_BASE_URL = 'https://app.uniswap.org/#'

function getUniswapSwapUrl({ fromCoin, toCoin, chainId }) {
  const inputCurrency = fromCoin?.addresses?.[chainId] ?? ""
  const outputCurrency = toCoin?.addresses?.[chainId] ?? ""
  return `${UNISWAP_BASE_URL}/swap?outputCurrency=${outputCurrency}`
}



/** Thanks @0xngmi for building defillama as a whole, it may be a thankless job but we appreciate it */
export const LLAMA_API_URL = "https://api.llama.fi/protocol/<placeholder>"
