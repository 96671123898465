import _ from 'lodash'

import { ChainId } from '@constants/networks'





/**
 * Represents an ERC20-like token with a unique address, chainId, and some metadata.
 */
export class Token {
  /**
   * @param {Object} obj - An object.
   * @param {Object.<number,string>} obj.addresses - addresses of the actual token
   * @param {Object.<number,string>} obj.addresses - addresses of the wrapper token in cases like gmx
   * @param {(number|Object.<number,number>)} obj.decimals - decimals of the currency
   * @param {string} obj.symbol - symbol of the currency
   * @param {string} obj.name - human readable name of the currency
   * @param {string} [obj.logo] - logo path
   * @param {string} [obj.description] - description of the token
   * @param {string} [obj.docUrl] - url link to the documentation
   */
  constructor({
    addresses,
    wrapperAddresses,
    decimals,
    symbol,
    name,
    logo,

    poolTokens,
    depositTokens,
    nativeTokens,
    description,
    docUrl,

    isNative,
  }) {


    this.addresses            = addresses
    this.wrapperAddresses     = wrapperAddresses
    // this.decimals             = decimals
    this.decimals             = makeMultiChainObj(decimals)
    this.symbol               = symbol
    this.name                 = name
    this.icon                 = logo

    this.nativeTokens   = nativeTokens ?? poolTokens
    this.depositTokens  = depositTokens ?? this.nativeTokens
    this.description    = description
    this.docUrl         = docUrl ?? ''

    this.isNative = isNative ?? false

  }
}



function makeMultiChainObj(valOrObj) {
  if (_.isObject(valOrObj)) {
    return valOrObj
  } else {
    const obj = {}
    for (const [chainName, chainId] of _.entries(ChainId)) {
      obj[chainId] = valOrObj
    }
    return obj
  }

}