
import { createConfig } from '@wagmi/core'
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  metaMaskWallet,
  rabbyWallet,
  coinbaseWallet,
  rainbowWallet,
  walletConnectWallet,
  trustWallet,
  ledgerWallet,
  frameWallet,
  safeWallet,
  injectedWallet
} from '@rainbow-me/rainbowkit/wallets'

import { createTransports } from '@/utils/createTransports'
import { supportedChains } from '@/constants/chains'



const APP_DETAILS = {
  appName: 'OnlyApes',
  projectId: 'YOUR_PROJECT_ID',
}


const WALLET_GROUPS = [
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet,
      coinbaseWallet,
      rabbyWallet,
      rainbowWallet,
      frameWallet,
    ],
  },
  {
    groupName: "Other",
    wallets: [
      injectedWallet,
      ledgerWallet,
      walletConnectWallet,
      trustWallet,
      safeWallet,
    ]
  }
]

const connectors = connectorsForWallets(WALLET_GROUPS, APP_DETAILS)

const transports = createTransports(supportedChains)

export const wagmiConfig = createConfig({
  connectors,
  chains: supportedChains,
  transports,
  // ssr: false,
})