// import { Transport, type Chain } from 'viem'
import { fallback, http } from '@wagmi/core'

import { CHAINS_BY_ID } from '@/constants/chains'

// type Transports = Record<Chain['id'], Transport>

export const createTransports = (chains) => {
  return chains.reduce((acc, chain) => {
    console.log(chain.rpcUrls.default.http[0])
    const apeChain = CHAINS_BY_ID[chain.id]

    acc[chain.id] = fallback([
        http(chain.rpcUrls.default.http[0])
    //   http(apeChain.rpcUrls.primary),
    //   http(apeChain.rpcUrls.fallback),
    ])
    return acc
  }, {})
}