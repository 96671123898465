// Generated by @wagmi/cli@1.3.0 on 12/4/2023 at 5:59:36 PM
import {
  // useContractRead,
  useContractWrite,
  useSimulateContract,
  useWatchContractEvent,
} from 'wagmi'

import { useContractRead } from '@hooks/wagmiHooks'
import { narrow } from 'abitype'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BattleRoyale
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export const battleRoyaleABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'gameId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'playerCap',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'players',
        internalType: 'address[]',
        type: 'address[]',
        indexed: false,
      },
      {
        name: 'betAmount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'tokenAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'GameCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'gameId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'playerAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'betAmount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'tokenAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'GamePlayerDeposit',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'gameId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'players',
        internalType: 'address[]',
        type: 'address[]',
        indexed: false,
      },
      {
        name: 'betAmount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'tokenAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'winner',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'GameResult',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'newAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleRevoked',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MAX_ALLOWED_PLAYERS',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ORACLE_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'WETH_ADDRESS',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ZERO_ADDRESS',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'allowedTokens',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'betCounter',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
    ],
    name: 'claimFees',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'playerCap', internalType: 'uint256', type: 'uint256' },
      { name: 'betAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'gameInfo', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'createGame',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feePercentage',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'feesCollected',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'gameCounter',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'player', internalType: 'address', type: 'address' },
    ],
    name: 'gamePlayerBetStatus',
    outputs: [{ name: 'status', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'games',
    outputs: [
      { name: 'playerCap', internalType: 'uint256', type: 'uint256' },
      { name: 'betAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'winner', internalType: 'address', type: 'address' },
      { name: 'gameInfo', internalType: 'bytes32', type: 'bytes32' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getGames',
    outputs: [
      {
        name: '',
        internalType: 'struct BattleRoyale.Game[]',
        type: 'tuple[]',
        components: [
          { name: 'playerCap', internalType: 'uint256', type: 'uint256' },
          { name: 'betAmount', internalType: 'uint256', type: 'uint256' },
          { name: 'tokenAddress', internalType: 'address', type: 'address' },
          { name: 'winner', internalType: 'address', type: 'address' },
          { name: 'gameInfo', internalType: 'bytes32', type: 'bytes32' },
          { name: 'players', internalType: 'address[]', type: 'address[]' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'playerAddress', internalType: 'address', type: 'address' },
    ],
    name: 'playerPlaceBet',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'playerAddress', internalType: 'address', type: 'address' },
    ],
    name: 'playerPlaceBetNative',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'winner', internalType: 'address', type: 'address' },
    ],
    name: 'resolveGame',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'allowed', internalType: 'bool', type: 'bool' },
    ],
    name: 'setAllowedToken',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'fee', internalType: 'uint256', type: 'uint256' }],
    name: 'setFeePercentage',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'gameId', internalType: 'uint256', type: 'uint256' }],
    name: 'showPlayersInGame',
    outputs: [{ name: '', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'weth',
    outputs: [{ name: '', internalType: 'contract WETH', type: 'address' }],
  },
  { stateMutability: 'payable', type: 'receive' },
]

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export const battleRoyaleAddress = {
  8453: '0xB5e3F02A996642D3138cFf6Fa45A813270076186',
}

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export const battleRoyaleConfig = {
  address: battleRoyaleAddress,
  abi: battleRoyaleABI,
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MultiBet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export const multiBetABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'betId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'gameId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'player',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'outcome',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: false,
      },
      {
        name: 'amount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'BetPlaced',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'gameId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'platform',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'name',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: false,
      },
      {
        name: 'details',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: false,
      },
      {
        name: 'outcomes',
        internalType: 'bytes32[]',
        type: 'bytes32[]',
        indexed: false,
      },
      {
        name: 'endTimestamp',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'tokenAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'GameCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'gameId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'betId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'player',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'amount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'PayoutMade',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'newAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleRevoked',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ORACLE_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'WETH_ADDRESS',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ZERO_ADDRESS',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'allowedTokens',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'playerAddress', internalType: 'address', type: 'address' },
    ],
    name: 'bet',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'betCounter',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'betNative',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'playerAddress', internalType: 'address', type: 'address' },
    ],
    name: 'checkIfPlayerHasBet',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
    ],
    name: 'claimFees',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'platform', internalType: 'bytes32', type: 'bytes32' },
      { name: 'name', internalType: 'bytes32', type: 'bytes32' },
      { name: 'details', internalType: 'bytes32', type: 'bytes32' },
      { name: 'outcomes', internalType: 'bytes32[]', type: 'bytes32[]' },
      { name: 'endTimestamp', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
    ],
    name: 'createGame',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feePercentage',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'feesCollected',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'gameBets',
    outputs: [
      { name: 'betId', internalType: 'uint256', type: 'uint256' },
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'playerAddress', internalType: 'address', type: 'address' },
      {
        name: 'status',
        internalType: 'enum MultiBet.BetStatus',
        type: 'uint8',
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'gameCounter',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'games',
    outputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'platform', internalType: 'bytes32', type: 'bytes32' },
      { name: 'name', internalType: 'bytes32', type: 'bytes32' },
      { name: 'details', internalType: 'bytes32', type: 'bytes32' },
      { name: 'endTimestamp', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
      { name: 'creationTimestamp', internalType: 'uint256', type: 'uint256' },
      { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'playerAddress', internalType: 'address', type: 'address' },
    ],
    name: 'getBet',
    outputs: [
      {
        name: '',
        internalType: 'struct MultiBet.Bet',
        type: 'tuple',
        components: [
          { name: 'betId', internalType: 'uint256', type: 'uint256' },
          { name: 'gameId', internalType: 'uint256', type: 'uint256' },
          { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'playerAddress', internalType: 'address', type: 'address' },
          {
            name: 'status',
            internalType: 'enum MultiBet.BetStatus',
            type: 'uint8',
          },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'gameId', internalType: 'uint256', type: 'uint256' }],
    name: 'getBetAggInfo',
    outputs: [
      {
        name: '',
        internalType: 'struct MultiBet.BetAggInfo[]',
        type: 'tuple[]',
        components: [
          { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
          { name: 'count', internalType: 'uint256', type: 'uint256' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getBets',
    outputs: [
      {
        name: '',
        internalType: 'struct MultiBet.Bet[]',
        type: 'tuple[]',
        components: [
          { name: 'betId', internalType: 'uint256', type: 'uint256' },
          { name: 'gameId', internalType: 'uint256', type: 'uint256' },
          { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'playerAddress', internalType: 'address', type: 'address' },
          {
            name: 'status',
            internalType: 'enum MultiBet.BetStatus',
            type: 'uint8',
          },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'playerAddress', internalType: 'address', type: 'address' },
    ],
    name: 'getBetsByAddress',
    outputs: [
      {
        name: '',
        internalType: 'struct MultiBet.Bet[]',
        type: 'tuple[]',
        components: [
          { name: 'betId', internalType: 'uint256', type: 'uint256' },
          { name: 'gameId', internalType: 'uint256', type: 'uint256' },
          { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'playerAddress', internalType: 'address', type: 'address' },
          {
            name: 'status',
            internalType: 'enum MultiBet.BetStatus',
            type: 'uint8',
          },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'gameId', internalType: 'uint256', type: 'uint256' }],
    name: 'getBetsByGame',
    outputs: [
      {
        name: '',
        internalType: 'struct MultiBet.Bet[]',
        type: 'tuple[]',
        components: [
          { name: 'betId', internalType: 'uint256', type: 'uint256' },
          { name: 'gameId', internalType: 'uint256', type: 'uint256' },
          { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'playerAddress', internalType: 'address', type: 'address' },
          {
            name: 'status',
            internalType: 'enum MultiBet.BetStatus',
            type: 'uint8',
          },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'gameId', internalType: 'uint256', type: 'uint256' }],
    name: 'getGameOutcomes',
    outputs: [{ name: '', internalType: 'bytes32[]', type: 'bytes32[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getGames',
    outputs: [
      {
        name: '',
        internalType: 'struct MultiBet.Game[]',
        type: 'tuple[]',
        components: [
          { name: 'gameId', internalType: 'uint256', type: 'uint256' },
          { name: 'platform', internalType: 'bytes32', type: 'bytes32' },
          { name: 'name', internalType: 'bytes32', type: 'bytes32' },
          { name: 'details', internalType: 'bytes32', type: 'bytes32' },
          { name: 'endTimestamp', internalType: 'uint256', type: 'uint256' },
          { name: 'tokenAddress', internalType: 'address', type: 'address' },
          { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
          {
            name: 'creationTimestamp',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
          { name: 'outcomes', internalType: 'bytes32[]', type: 'bytes32[]' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'playerAddress', internalType: 'address', type: 'address' },
    ],
    name: 'getGamesPlayerIsIn',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'player', internalType: 'address', type: 'address' },
    ],
    name: 'hasClaimed',
    outputs: [{ name: 'claimedStatus', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'outcomeBets',
    outputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'payoutAddress', internalType: 'address', type: 'address' },
    ],
    name: 'payout',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'gameId', internalType: 'uint256', type: 'uint256' }],
    name: 'payoutAll',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'player', internalType: 'address', type: 'address' },
    ],
    name: 'playerBets',
    outputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'player', internalType: 'address', type: 'address' },
    ],
    name: 'playerOutcome',
    outputs: [{ name: 'outcome', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'allowed', internalType: 'bool', type: 'bool' },
    ],
    name: 'setAllowedToken',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'fee', internalType: 'uint256', type: 'uint256' }],
    name: 'setFeePercentage',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'outcome', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'setOutcome',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'weth',
    outputs: [{ name: '', internalType: 'contract WETH', type: 'address' }],
  },
  { stateMutability: 'payable', type: 'receive' },
]

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export const multiBetAddress = {
  8453: '0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482',
}

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export const multiBetConfig = { address: multiBetAddress, abi: multiBetABI }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PvpErcVersion
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export const pvpErcVersionABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'gameId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'player1',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'player2',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'betAmount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'tokenAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'GameCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'gameId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'playerAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'betAmount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'tokenAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'player1PlacedBet',
        internalType: 'bool',
        type: 'bool',
        indexed: false,
      },
      {
        name: 'player2PlacedBet',
        internalType: 'bool',
        type: 'bool',
        indexed: false,
      },
    ],
    name: 'GamePlayerDeposit',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'gameId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'player1',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'player2',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'betAmount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'tokenAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'winner',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'GameResult',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'newAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleRevoked',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ORACLE_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'WETH_ADDRESS',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ZERO_ADDRESS',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'allowedTokens',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'betCounter',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
    ],
    name: 'claimFees',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'player1', internalType: 'address', type: 'address' },
      { name: 'player2', internalType: 'address', type: 'address' },
      { name: 'betAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'gameInfo', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'createGame',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feePercentage',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'feesCollected',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'gameCounter',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'games',
    outputs: [
      { name: 'player1', internalType: 'address', type: 'address' },
      { name: 'player2', internalType: 'address', type: 'address' },
      { name: 'betAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'player1PlacedBet', internalType: 'bool', type: 'bool' },
      { name: 'player2PlacedBet', internalType: 'bool', type: 'bool' },
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'winner', internalType: 'address', type: 'address' },
      { name: 'gameInfo', internalType: 'bytes32', type: 'bytes32' },
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
      { name: 'creationTimestamp', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getGames',
    outputs: [
      {
        name: '',
        internalType: 'struct PvpErcVersion.Game[]',
        type: 'tuple[]',
        components: [
          { name: 'player1', internalType: 'address', type: 'address' },
          { name: 'player2', internalType: 'address', type: 'address' },
          { name: 'betAmount', internalType: 'uint256', type: 'uint256' },
          { name: 'player1PlacedBet', internalType: 'bool', type: 'bool' },
          { name: 'player2PlacedBet', internalType: 'bool', type: 'bool' },
          { name: 'tokenAddress', internalType: 'address', type: 'address' },
          { name: 'winner', internalType: 'address', type: 'address' },
          { name: 'gameInfo', internalType: 'bytes32', type: 'bytes32' },
          { name: 'gameId', internalType: 'uint256', type: 'uint256' },
          { name: 'creationBlock', internalType: 'uint256', type: 'uint256' },
          {
            name: 'creationTimestamp',
            internalType: 'uint256',
            type: 'uint256',
          },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'playerAddress', internalType: 'address', type: 'address' },
    ],
    name: 'playerPlaceBet',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'playerAddress', internalType: 'address', type: 'address' },
    ],
    name: 'playerPlaceBetNative',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'gameId', internalType: 'uint256', type: 'uint256' },
      { name: 'player1Wins', internalType: 'bool', type: 'bool' },
    ],
    name: 'resolveGame',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'allowed', internalType: 'bool', type: 'bool' },
    ],
    name: 'setAllowedToken',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'fee', internalType: 'uint256', type: 'uint256' }],
    name: 'setFeePercentage',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'weth',
    outputs: [{ name: '', internalType: 'contract WETH', type: 'address' }],
  },
  { stateMutability: 'payable', type: 'receive' },
]

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export const pvpErcVersionAddress = {
  8453: '0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec',
}

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export const pvpErcVersionConfig = {
  address: pvpErcVersionAddress,
  abi: pvpErcVersionABI,
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UsernameRegistry
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export const usernameRegistryABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'platform', internalType: 'string', type: 'string' },
      { name: 'username', internalType: 'string', type: 'string' },
    ],
    name: 'addMyUser',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'platform', internalType: 'string', type: 'string' },
      { name: 'username', internalType: 'string', type: 'string' },
    ],
    name: 'addUser',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'addressToUser',
    outputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'platform', internalType: 'string', type: 'string' },
    ],
    name: 'checkAddressExists',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'platform', internalType: 'string', type: 'string' },
      { name: 'username', internalType: 'string', type: 'string' },
    ],
    name: 'checkUsernameExists',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getUserAddressList',
    outputs: [{ name: '', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'platform', internalType: 'string', type: 'string' },
    ],
    name: 'getUsername',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'a', internalType: 'string', type: 'string' },
      { name: 'b', internalType: 'string', type: 'string' },
    ],
    name: 'matchesPartial',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'string', type: 'string' },
      { name: '', internalType: 'string', type: 'string' },
    ],
    name: 'platformUsernameToAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'platform', internalType: 'string', type: 'string' },
      { name: 'partialUsername', internalType: 'string', type: 'string' },
    ],
    name: 'searchUsername',
    outputs: [{ name: '', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'userAddressList',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
]

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export const usernameRegistryAddress = {
  8453: '0x858180477779207F88B5C93920CE578602df4778',
}

/**
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export const usernameRegistryConfig = {
  address: usernameRegistryAddress,
  abi: usernameRegistryABI,
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleRead(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleDefaultAdminRole(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"MAX_ALLOWED_PLAYERS"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleMaxAllowedPlayers(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'MAX_ALLOWED_PLAYERS',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"ORACLE_ROLE"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleOracleRole(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'ORACLE_ROLE',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"WETH_ADDRESS"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleWethAddress(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'WETH_ADDRESS',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"ZERO_ADDRESS"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleZeroAddress(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'ZERO_ADDRESS',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"allowedTokens"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleAllowedTokens(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'allowedTokens',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"betCounter"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleBetCounter(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'betCounter',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"feePercentage"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleFeePercentage(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'feePercentage',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"feesCollected"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleFeesCollected(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'feesCollected',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"gameCounter"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleGameCounter(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'gameCounter',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"gamePlayerBetStatus"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleGamePlayerBetStatus(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'gamePlayerBetStatus',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"games"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleGames(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'games',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"getGames"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleGetGames(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'getGames',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"getRoleAdmin"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleGetRoleAdmin(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'getRoleAdmin',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"hasRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleHasRole(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'hasRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"showPlayersInGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleShowPlayersInGame(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'showPlayersInGame',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleSupportsInterface(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'supportsInterface',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"weth"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleWeth(config = {}) {
  return useContractRead({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'weth',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleWrite(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"claimFees"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleClaimFees(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'claimFees',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"createGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleCreateGame(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'createGame',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"grantRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleGrantRole(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'grantRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"playerPlaceBet"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyalePlayerPlaceBet(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'playerPlaceBet',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"playerPlaceBetNative"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyalePlayerPlaceBetNative(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'playerPlaceBetNative',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"renounceRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleRenounceRole(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'renounceRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"resolveGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleResolveGame(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'resolveGame',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"revokeRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleRevokeRole(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'revokeRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"setAllowedToken"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleSetAllowedToken(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'setAllowedToken',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"setFeePercentage"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleSetFeePercentage(config = {}) {
  return useContractWrite({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'setFeePercentage',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyaleWrite(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"claimFees"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyaleClaimFees(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'claimFees',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"createGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyaleCreateGame(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'createGame',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"grantRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyaleGrantRole(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'grantRole',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"playerPlaceBet"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyalePlayerPlaceBet(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'playerPlaceBet',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"playerPlaceBetNative"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyalePlayerPlaceBetNative(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'playerPlaceBetNative',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"renounceRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyaleRenounceRole(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'renounceRole',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"resolveGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyaleResolveGame(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'resolveGame',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"revokeRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyaleRevokeRole(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'revokeRole',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"setAllowedToken"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyaleSetAllowedToken(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'setAllowedToken',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link battleRoyaleABI}__ and `functionName` set to `"setFeePercentage"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function usePrepareBattleRoyaleSetFeePercentage(config = {}) {
  return useSimulateContract({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    functionName: 'setFeePercentage',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link battleRoyaleABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleEvent(config = {}) {
  return useWatchContractEvent({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link battleRoyaleABI}__ and `eventName` set to `"GameCreated"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleGameCreatedEvent(config = {}) {
  return useWatchContractEvent({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    eventName: 'GameCreated',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link battleRoyaleABI}__ and `eventName` set to `"GamePlayerDeposit"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleGamePlayerDepositEvent(config = {}) {
  return useWatchContractEvent({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    eventName: 'GamePlayerDeposit',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link battleRoyaleABI}__ and `eventName` set to `"GameResult"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleGameResultEvent(config = {}) {
  return useWatchContractEvent({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    eventName: 'GameResult',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link battleRoyaleABI}__ and `eventName` set to `"RoleAdminChanged"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleRoleAdminChangedEvent(config = {}) {
  return useWatchContractEvent({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    eventName: 'RoleAdminChanged',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link battleRoyaleABI}__ and `eventName` set to `"RoleGranted"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleRoleGrantedEvent(config = {}) {
  return useWatchContractEvent({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    eventName: 'RoleGranted',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link battleRoyaleABI}__ and `eventName` set to `"RoleRevoked"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0xB5e3F02A996642D3138cFf6Fa45A813270076186)
 */
export function useBattleRoyaleRoleRevokedEvent(config = {}) {
  return useWatchContractEvent({
    abi: battleRoyaleABI,
    address: battleRoyaleAddress[8453],
    eventName: 'RoleRevoked',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetRead(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetDefaultAdminRole(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"ORACLE_ROLE"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetOracleRole(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'ORACLE_ROLE',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"WETH_ADDRESS"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetWethAddress(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'WETH_ADDRESS',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"ZERO_ADDRESS"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetZeroAddress(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'ZERO_ADDRESS',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"allowedTokens"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetAllowedTokens(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'allowedTokens',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"betCounter"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetBetCounter(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'betCounter',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"checkIfPlayerHasBet"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetCheckIfPlayerHasBet(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'checkIfPlayerHasBet',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"feePercentage"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetFeePercentage(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'feePercentage',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"feesCollected"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetFeesCollected(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'feesCollected',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"gameBets"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGameBets(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'gameBets',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"gameCounter"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGameCounter(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'gameCounter',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"games"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGames(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'games',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"getBet"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGetBet(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'getBet',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"getBetAggInfo"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGetBetAggInfo(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'getBetAggInfo',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"getBets"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGetBets(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'getBets',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"getBetsByAddress"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGetBetsByAddress(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'getBetsByAddress',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"getBetsByGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGetBetsByGame(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'getBetsByGame',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"getGameOutcomes"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGetGameOutcomes(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'getGameOutcomes',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"getGames"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGetGames(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'getGames',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"getGamesPlayerIsIn"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGetGamesPlayerIsIn(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'getGamesPlayerIsIn',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"getRoleAdmin"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGetRoleAdmin(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'getRoleAdmin',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"hasClaimed"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetHasClaimed(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'hasClaimed',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"hasRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetHasRole(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'hasRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"outcomeBets"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetOutcomeBets(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'outcomeBets',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"playerBets"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetPlayerBets(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'playerBets',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"playerOutcome"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetPlayerOutcome(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'playerOutcome',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetSupportsInterface(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'supportsInterface',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"weth"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetWeth(config = {}) {
  return useContractRead({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'weth',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetWrite(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"bet"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetBet(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'bet',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"betNative"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetBetNative(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'betNative',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"claimFees"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetClaimFees(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'claimFees',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"createGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetCreateGame(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'createGame',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"grantRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGrantRole(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'grantRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"payout"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetPayout(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'payout',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"payoutAll"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetPayoutAll(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'payoutAll',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"renounceRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetRenounceRole(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'renounceRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"revokeRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetRevokeRole(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'revokeRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"setAllowedToken"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetSetAllowedToken(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'setAllowedToken',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"setFeePercentage"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetSetFeePercentage(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'setFeePercentage',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"setOutcome"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetSetOutcome(config = {}) {
  return useContractWrite({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'setOutcome',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetWrite(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"bet"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetBet(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'bet',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"betNative"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetBetNative(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'betNative',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"claimFees"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetClaimFees(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'claimFees',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"createGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetCreateGame(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'createGame',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"grantRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetGrantRole(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'grantRole',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"payout"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetPayout(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'payout',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"payoutAll"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetPayoutAll(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'payoutAll',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"renounceRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetRenounceRole(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'renounceRole',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"revokeRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetRevokeRole(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'revokeRole',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"setAllowedToken"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetSetAllowedToken(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'setAllowedToken',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"setFeePercentage"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetSetFeePercentage(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'setFeePercentage',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link multiBetABI}__ and `functionName` set to `"setOutcome"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function usePrepareMultiBetSetOutcome(config = {}) {
  return useSimulateContract({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    functionName: 'setOutcome',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link multiBetABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetEvent(config = {}) {
  return useWatchContractEvent({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link multiBetABI}__ and `eventName` set to `"BetPlaced"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetBetPlacedEvent(config = {}) {
  return useWatchContractEvent({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    eventName: 'BetPlaced',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link multiBetABI}__ and `eventName` set to `"GameCreated"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetGameCreatedEvent(config = {}) {
  return useWatchContractEvent({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    eventName: 'GameCreated',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link multiBetABI}__ and `eventName` set to `"PayoutMade"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetPayoutMadeEvent(config = {}) {
  return useWatchContractEvent({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    eventName: 'PayoutMade',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link multiBetABI}__ and `eventName` set to `"RoleAdminChanged"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetRoleAdminChangedEvent(config = {}) {
  return useWatchContractEvent({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    eventName: 'RoleAdminChanged',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link multiBetABI}__ and `eventName` set to `"RoleGranted"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetRoleGrantedEvent(config = {}) {
  return useWatchContractEvent({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    eventName: 'RoleGranted',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link multiBetABI}__ and `eventName` set to `"RoleRevoked"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x4736059Eb1CA126F5104d681Ce30aFe63eeb5482)
 */
export function useMultiBetRoleRevokedEvent(config = {}) {
  return useWatchContractEvent({
    abi: multiBetABI,
    address: multiBetAddress[8453],
    eventName: 'RoleRevoked',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionRead(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionDefaultAdminRole(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"ORACLE_ROLE"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionOracleRole(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'ORACLE_ROLE',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"WETH_ADDRESS"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionWethAddress(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'WETH_ADDRESS',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"ZERO_ADDRESS"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionZeroAddress(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'ZERO_ADDRESS',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"allowedTokens"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionAllowedTokens(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'allowedTokens',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"betCounter"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionBetCounter(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'betCounter',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"feePercentage"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionFeePercentage(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'feePercentage',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"feesCollected"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionFeesCollected(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'feesCollected',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"gameCounter"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionGameCounter(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'gameCounter',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"games"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionGames(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'games',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"getGames"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionGetGames(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'getGames',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"getRoleAdmin"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionGetRoleAdmin(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'getRoleAdmin',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"hasRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionHasRole(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'hasRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionSupportsInterface(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'supportsInterface',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"weth"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionWeth(config = {}) {
  return useContractRead({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'weth',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionWrite(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"claimFees"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionClaimFees(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'claimFees',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"createGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionCreateGame(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'createGame',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"grantRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionGrantRole(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'grantRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"playerPlaceBet"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionPlayerPlaceBet(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'playerPlaceBet',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"playerPlaceBetNative"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionPlayerPlaceBetNative(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'playerPlaceBetNative',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"renounceRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionRenounceRole(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'renounceRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"resolveGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionResolveGame(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'resolveGame',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"revokeRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionRevokeRole(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'revokeRole',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"setAllowedToken"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionSetAllowedToken(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'setAllowedToken',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"setFeePercentage"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionSetFeePercentage(config = {}) {
  return useContractWrite({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'setFeePercentage',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionWrite(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"claimFees"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionClaimFees(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'claimFees',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"createGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionCreateGame(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'createGame',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"grantRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionGrantRole(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'grantRole',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"playerPlaceBet"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionPlayerPlaceBet(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'playerPlaceBet',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"playerPlaceBetNative"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionPlayerPlaceBetNative(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'playerPlaceBetNative',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"renounceRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionRenounceRole(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'renounceRole',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"resolveGame"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionResolveGame(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'resolveGame',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"revokeRole"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionRevokeRole(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'revokeRole',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"setAllowedToken"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionSetAllowedToken(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'setAllowedToken',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link pvpErcVersionABI}__ and `functionName` set to `"setFeePercentage"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePreparePvpErcVersionSetFeePercentage(config = {}) {
  return useSimulateContract({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    functionName: 'setFeePercentage',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link pvpErcVersionABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionEvent(config = {}) {
  return useWatchContractEvent({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link pvpErcVersionABI}__ and `eventName` set to `"GameCreated"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionGameCreatedEvent(config = {}) {
  return useWatchContractEvent({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    eventName: 'GameCreated',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link pvpErcVersionABI}__ and `eventName` set to `"GamePlayerDeposit"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionGamePlayerDepositEvent(config = {}) {
  return useWatchContractEvent({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    eventName: 'GamePlayerDeposit',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link pvpErcVersionABI}__ and `eventName` set to `"GameResult"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionGameResultEvent(config = {}) {
  return useWatchContractEvent({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    eventName: 'GameResult',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link pvpErcVersionABI}__ and `eventName` set to `"RoleAdminChanged"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionRoleAdminChangedEvent(config = {}) {
  return useWatchContractEvent({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    eventName: 'RoleAdminChanged',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link pvpErcVersionABI}__ and `eventName` set to `"RoleGranted"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionRoleGrantedEvent(config = {}) {
  return useWatchContractEvent({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    eventName: 'RoleGranted',
    ...config,
  })
}

/**
 * Wraps __{@link useWatchContractEvent}__ with `abi` set to __{@link pvpErcVersionABI}__ and `eventName` set to `"RoleRevoked"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x3BEf5b32f7208f466dc012841b5EE243beAeE2Ec)
 */
export function usePvpErcVersionRoleRevokedEvent(config = {}) {
  return useWatchContractEvent({
    abi: pvpErcVersionABI,
    address: pvpErcVersionAddress[8453],
    eventName: 'RoleRevoked',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryRead(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"addressToUser"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryAddressToUser(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'addressToUser',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"checkAddressExists"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryCheckAddressExists(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'checkAddressExists',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"checkUsernameExists"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryCheckUsernameExists(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'checkUsernameExists',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"getUserAddressList"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryGetUserAddressList(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'getUserAddressList',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"getUsername"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryGetUsername(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'getUsername',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"matchesPartial"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryMatchesPartial(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'matchesPartial',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"platformUsernameToAddress"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryPlatformUsernameToAddress(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'platformUsernameToAddress',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"searchUsername"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistrySearchUsername(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'searchUsername',
    ...config,
  })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"userAddressList"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryUserAddressList(config = {}) {
  return useContractRead({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'userAddressList',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link usernameRegistryABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryWrite(config = {}) {
  return useContractWrite({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"addMyUser"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryAddMyUser(config = {}) {
  return useContractWrite({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'addMyUser',
    ...config,
  })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"addUser"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function useUsernameRegistryAddUser(config = {}) {
  return useContractWrite({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'addUser',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link usernameRegistryABI}__.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function usePrepareUsernameRegistryWrite(config = {}) {
  return useSimulateContract({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"addMyUser"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function usePrepareUsernameRegistryAddMyUser(config = {}) {
  return useSimulateContract({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'addMyUser',
    ...config,
  })
}

/**
 * Wraps __{@link useSimulateContract}__ with `abi` set to __{@link usernameRegistryABI}__ and `functionName` set to `"addUser"`.
 *
 * [__View Contract on Base Basescan__](https://basescan.org/address/0x858180477779207F88B5C93920CE578602df4778)
 */
export function usePrepareUsernameRegistryAddUser(config = {}) {
  return useSimulateContract({
    abi: usernameRegistryABI,
    address: usernameRegistryAddress[8453],
    functionName: 'addUser',
    ...config,
  })
}
