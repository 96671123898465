
import XBL_ICON_SRC from "@assets/platforms/xbl.svg"
import PSN_ICON_SRC from "@assets/platforms/psn.svg"
import BNET_ICON_SRC from "@assets/platforms/bnet.svg"
import RIOT_ICON_SRC from "@assets/platforms/riot.svg"
import STEAM_ICON_SRC from "@assets/platforms/steam.svg"
import TWITCH_ICON_SRC from "@assets/platforms/twitch-tile.svg"

export const PLATFORM_INFO_LIST = [
  {
    platformId:  "RIOT",
    title:       "Riot Games",
    shortTitle:  "Riot",
    description: "Use this for riot games such as Valorant",
    icon:        RIOT_ICON_SRC
  },{
    platformId:  "XBL",
    title:       "Xbox Live",
    shortTitle:  "Xbox",
    description: "Use this if you have an Xbox, Xbox 360, Xbox One, or game using other Microsoft related platforms",
    icon:        XBL_ICON_SRC
  }, {
    platformId:  "PSN",
    title:       "PlayStation Network",
    shortTitle:  "PlayStation",
    description: "Use this if you're gaming on PlayStation/PSN/Sony",
    icon:        PSN_ICON_SRC
  }, {
    platformId:  "BATTLENET",
    title:       "Battle.net",
    shortTitle:  "Battle.net",
    description: "Use this for battle.net/Blizzard/activision platforms/logins",
    icon:        BNET_ICON_SRC
  }, {
    platformId:  "STEAM",
    title:       "Steam Network",
    shortTitle:  "Steam",
    description: "Steam platform for PC gaming",
    icon:        STEAM_ICON_SRC
  }, {
    platformId:  "TWITCH",
    title:       "Twitch",
    shortTitle:  "Twitch",
    description: "Twitch streaming platform for gamers",
    icon:        TWITCH_ICON_SRC
  }
]

let PLATFORM_INFO_OBJ = {}
for (const platformObj of PLATFORM_INFO_LIST) {
  PLATFORM_INFO_OBJ[platformObj.platformId] = {
    ...platformObj,
    id: platformObj.platformId,
    label: platformObj.title
  }
}

export { PLATFORM_INFO_OBJ }